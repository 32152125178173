import React, { useState } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import Image from "../elements/Image";
import Modal from "../elements/Modal";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  };

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  };

  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <Image
            className="has-shadow"
            src={require("./../../assets/images/ppd.png")}
            alt="Hero"
            width={896}
            height={504}
          />
          <Image
            className="has-shadow"
            src={require("./../../assets/images/gtaponglogo1.png")}
            alt="Hero"
            width={896}
            height={504}
          />
          <div className="hero-content">
            <h1
              className="mt-0 mb-16 reveal-from-bottom"
              data-reveal-delay="200"
            >
              GTA Pong Annual Meetup 2022
              <span className="text-color-primary">
                Sponsored by: PingPongDepot.com
              </span>
            </h1>
            <div className="container-xs">
              <p
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
              >
                <a href="https://www.google.ca/travel/hotels/Greater%20Toronto%20Area/entity/CgoIiJ_-mO7l3f0REAE/overview?utm_campaign=sharing&utm_medium=link&utm_source=htls&ts=CAESABogCgIaABIaEhQKBwjmDxAGGAUSBwjmDxAGGAYYATICEAAqBAoAGgA&ved=0CAAQ5JsGahcKEwig_Or17JH4AhUAAAAAHQAAAAAQLg">
                  {" "}
                  Congrats To Our Tournament Winners!
                </a>
              </p>
              <p
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
              >
                Ed * Ray * Gabriel * Harshill
              </p>
              {/* <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
              Entry Fee: 6 pack of beer for the housemates :D
                </p> */}
              <div className="reveal-from-bottom" data-reveal-delay="600">
                {/* <ButtonGroup>
                  <Button tag="a" color="primary" wideMobile href="https://www.facebook.com/Mimos.Coliving">
                    Location Details
                    </Button>
                  <Button tag="a" color="dark" wideMobile href="https://gtapong.com/downtown-toronto">
                    Last Year's Event
                    </Button>
                </ButtonGroup> */}
              </div>
            </div>
          </div>
          <div
            className="hero-figure reveal-from-bottom illustration-element-01"
            data-reveal-value="20px"
            data-reveal-delay="800"
          >
            {/* <a
              data-video="https://player.vimeo.com/video/174002812"
              href="#0"
              aria-controls="video-modal"
              onClick={openModal}
            > */}

            <Image
              className="has-shadow"
              src={require("./../../assets/images/mimos2.jpeg")}
              alt="Hero"
              width={896}
              height={504}
            />

            <Image
              className="has-shadow"
              src={require("./../../assets/images/june4/01.jpeg")}
              alt="Hero"
              width={896}
              height={504}
            />

            <Image
              className="has-shadow"
              src={require("./../../assets/images/june4/02.jpeg")}
              alt="Hero"
              width={896}
              height={504}
            />

            <Image
              className="has-shadow"
              src={require("./../../assets/images/june4/03.jpeg")}
              alt="Hero"
              width={896}
              height={504}
            />

            <Image
              className="has-shadow"
              src={require("./../../assets/images/june4/04.jpeg")}
              alt="Hero"
              width={896}
              height={504}
            />

            <Image
              className="has-shadow"
              src={require("./../../assets/images/june4/05.jpeg")}
              alt="Hero"
              width={896}
              height={504}
            />

            <Image
              className="has-shadow"
              src={require("./../../assets/images/june4/06.jpeg")}
              alt="Hero"
              width={896}
              height={504}
            />

            <Image
              className="has-shadow"
              src={require("./../../assets/images/june4/07.jpeg")}
              alt="Hero"
              width={896}
              height={504}
            />

            <Image
              className="has-shadow"
              src={require("./../../assets/images/june4/08.jpeg")}
              alt="Hero"
              width={896}
              height={504}
            />

            <Image
              className="has-shadow"
              src={require("./../../assets/images/june4/09.jpeg")}
              alt="Hero"
              width={896}
              height={504}
            />

            <Image
              className="has-shadow"
              src={require("./../../assets/images/june4/10.jpeg")}
              alt="Hero"
              width={896}
              height={504}
            />

            <Image
              className="has-shadow"
              src={require("./../../assets/images/june4/11.jpeg")}
              alt="Hero"
              width={896}
              height={504}
            />

            {/* </a> */}
          </div>
          {/* <Modal
            id="video-modal"
            show={videoModalActive}
            handleClose={closeModal}
            video="https://player.vimeo.com/video/174002812"
            videoTag="iframe" /> */}
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
