import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Next Meetup: June 26, 2022',
    paragraph: 'Endrew\'s Place: 161 Athol St E, Oshawa, ON L1H 1K2, Canada'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >

      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
<a href="https://goo.gl/maps/9FkEAioChXag1tUh6">View on Google Maps</a>
            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Carpooling Available
                  </div>
                <h3 className="mt-0 mb-12">
                  9 Confirmed / Interested So Far: 
                  </h3>
                <p className="m-0">
Kazi * Harshill * Ray * Antony * Jimmy * Mazz * Naj *  Troy * Endrew                 </p>
              </div>
              
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/endrews/01.jpeg')}
                  alt="Features split 01"
                  width={528}
                  height={396} /> 
              </div>

              
            </div>
            <Image
              className="has-shadow"
              src={require("./../../assets/images/endrews/03.jpeg")}
              alt="Hero"
              width={896}
              height={504}
            />

<Image
              className="has-shadow"
              src={require("./../../assets/images/endrews/04.jpeg")}
              alt="Hero"
              width={896}
              height={504}
            />

<Image
              className="has-shadow"
              src={require("./../../assets/images/endrews/05.png")}
              alt="Hero"
              width={896}
              height={504}
            />

<Image
              className="has-shadow"
              src={require("./../../assets/images/endrews/06.png")}
              alt="Hero"
              width={896}
              height={504}
            />

            {/* <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Alcohol Permitted
                  </div>
                <h3 className="mt-0 mb-12">
                  I think it lasted till night time last year.
                  </h3>
                <p className="m-0">
Our guy Endrew *right* is kindly offering to host a rally in Oshawa next weekend!       </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-02.png')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div> */}

            {/* <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Our main guy
                  </div>
                <h3 className="mt-0 mb-12">
                  Harshill
                  </h3>
                <p className="m-0">
A very good TT player and he's generously offered to help coordinate this year's annual meetup! George is currently out of town. Please msg Harshill with any questions or upon arrival (647.518.3414)                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  // imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-03.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} />

                
              </div>
            </div> */}

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;