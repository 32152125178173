import React from 'react';
// import sections
// import GenericSection from '../components/sections/GenericSection';
// import Hero from '../components/sections/Hero';
// import FeaturesTiles from '../components/sections/FeaturesTiles';
// import FeaturesSplit from '../components/sections/FeaturesSplit';
// import Testimonial from '../components/sections/Testimonial';
// import Cta from '../components/sections/Cta';
import MapContainer from '../components/sections/MapContainer';
import gtaponglogo1 from '../assets/images/gtaponglogo1.png';

const Gtamap = () => {

  return (
    <>
      <MapContainer className="illustration-section-01" />
      <div>
        <img style={{display: "block", width: "300px", margin: "auto"}} src={require("../assets/images/gtaponglogo1.png")} alt="GTA Pong Logo" />
      </div>
    </>
  );
}

export default Gtamap;