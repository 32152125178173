import React, { useState } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import snazzyStyles from "./mapStyles";
import images from "./images.js";
//import '../../App.css';

export const MapContainer = () => {
  const [selected, setSelected] = useState({});
  const [center, setCenter] = useState({
    lat: 43.885382210661355,
    lng: -79.43903333567872,
  });

  const onSelect = (item) => {
    setSelected(item);
    setCenter(item.location);
  };

  const dapStyles = {
    height: "100vh",
    width: "100%",
  };

  const locations = [
    {
      name: "Endrew's Apartment Building",
      icon: images.e,
      location: { lat: 43.8980791757363, lng: -78.8569882573211 },
      content:
        '<h4>Endrew\'s Apartment Building</h4><br><p>161 Athol St E, Oshawa, ON L1H 1K2, Canada<br><a href="https://goo.gl/maps/9LMeu3YB6eAW1CLQ6" target="blank">View on Google Maps</a><br><a href="https://projectz.gtapong.com" target="blank">Pics & Details</a>',
      address: "161 Athol St E, Oshawa, ON L1H 1K2, Canada",
      gmaps: "https://goo.gl/maps/9LMeu3YB6eAW1CLQ6",
      details: "https://projectz.gtapong.com",
    },
    {
      name: "Alisa's Guesthouse",
      icon: images.a,
      location: { lat: 43.66807859792177, lng: -79.40174321844772 },
      address: "11 Spadina Rd, Toronto, ON M5R 2S9, Canada",
      gmaps: "https://goo.gl/maps/C4GbMBZVzZGDVs9N9",
      details: "https://gtapong.com/downtown-toronto",
    },
    {
      name: "Naveen's Shop",
      icon: images.n,
      location: { lat: 43.71961688608994, lng: -79.4570254757975 },
      address: "3220 Dufferin St Unit 14 A, North York, ON M6A 2T3, Canada",
      gmaps: "https://goo.gl/maps/DESJ9kbWKDDrzYwz5",
      details: "https://gtapong.com/yorkdale-pong"
    },
    {
      name: "Papa John's Country Crib",
      icon: images.j,
      location: { lat: 44.09130538401612, lng: -80.20728500675915 },
      address: "near 301 Col Phillips Dr, Shelburne, ON L9V 3W1, Canada",
      gmaps: "https://goo.gl/maps/2vqoAzajYTn89b7N7",
      details: "https://gtapong.com/shelburne-pong",
    },
    {
      name: "Endrew's Bradford Crib",
      icon: images.b,
      location: { lat: 44.107368081219384, lng: -79.5867931206362 },
      address: "near 470 Holland St W, Bradford, ON L3Z 0A2, Canada",
      gmaps: "https://goo.gl/maps/qVw6uYvv8HKd2Kv88",
      details: "https://gtapong.com/bradford-pong",
    },
    {
      name: "Tony's Pro Basement Setup",
      icon: images.t,
      location: { lat: 44.04367894177704, lng: -79.81154247779354 },
      address: "near Hillside Community Church, a short drive just north of downtown Tottenham",
      gmaps: "https://goo.gl/maps/AXgTw4Wi6URjZqpi8",
      details: "https://gtapong.com/tottenham"
    },
    {
      name: "Serg's Garage",
      icon: images.s,
      location: { lat: 43.70869268295808, lng: -79.4873056740474 },
      address: "Jacinta Drive, Toronto, ON, Canada ",
      gmaps: "https://goo.gl/maps/eLXzq4SLf1kofKdFA",
      details: "../../assets/images/art/sergs.jpeg",},
    {
      name: "Our June 26th Event Sponsor: F.G. Bradley's",
      icon: images.fg,
      location: { lat: 43.892167075453656, lng: -78.87892132720533 },
      address: "Oshawa Centre, Oshawa, ON L1J 2K5",
      gmaps: "https://g.page/FGBradleys-Oshawa?share",
      details: "https://www.fgbradleys.com/category/115/Table-Tennis-"},
    {
      name: "Quintin's Dope Garage Setup!",
      icon: images.q,
      location: { lat: 43.41985947743205, lng: -79.6940359926407 },
      address: "bordering Thomas A Blakelock highschool in Oakville (George's old highschool!)",
      gmaps: "https://goo.gl/maps/bs3fbcPn7Jb76AxC6",
      details: "https://gtapong.com/oakville"
    },

    {
      name: "Shooterville Burlington",
      icon: images.s,
      location: { lat: 43.33666322493971, lng: -79.81763631889076 }, 
      address: "1400 Plains Rd E #202, Burlington, ON L7R 3P8, Canada",
      gmaps: "https://g.page/Shooterville314?share",
      details: "https://www.shooterville.com/gallery?pgid=jlzpsna7-d7e2ccd4-3d67-4841-91ee-cb6d45779fc4"
    },

    {
      name: "Absolute World (contact Anas)",
      icon: images.a,
      location: { lat: 43.59518210475591, lng: -79.63418559824987 },
      address: "90 Absolute Ave, Mississauga, ON L4Z 0A3, Canada",
      gmaps: "https://goo.gl/maps/yFVtj1A3d8oE9wqu9",
      details: "https://en.wikipedia.org/wiki/Absolute_World"
    },

    {
      name: "Terry's House",
      icon: images.t,
      location: { lat: 43.70629116151092, lng: -79.26817822165387 },
      address: "Birchmount & Danforth, Scarborough. Ask George for Terry's #",
      gmaps: "https://goo.gl/maps/Nj3JfT3e8gZLghdE8",
      details: ""
    },

    {
      name: "My TableTennis Club Mississauga",
      icon: images.m,
      location: { lat: 43.593751689925156, lng: -79.59147173709364 },
      address: "2436 Haines Rd, Mississauga, ON L4Y 1Y6, Canada",
      gmaps: "https://g.page/my-table-tennis-mississauga?share",
      details: "https://mytabletennisclub.ca/contact-us/"
    },

    {
      name: "Progress Table Tennis Club",
      icon: images.p,
      location: { lat: 43.77335293073925, lng: -79.26996068154979 },
      address: "291 Progress Ave Unit C, Scarborough, ON M1P 2Z2, Canada",
      gmaps: "https://goo.gl/maps/h88QwZVgY9KyCUFPA",
      details: "https://www.progresspingpong.ca/"
    },
    // {
    //   name: "Endrew's Bradford Crib",
    //   icon: images.b,
    //   location: { lat: 44.107368081219384, lng: -79.5867931206362 },
    //   content: '<h4>Endrew\'s Bradford Crib</h4><br><p>near 470 Holland St W, Bradford, ON L3Z 0A2, Canada<br><a href="https://goo.gl/maps/qVw6uYvv8HKd2Kv88" target="blank">View on Google Maps</a><br><a href="https://gtapong.com/bradford-pong" target="blank">Pics & Details</a>',
    // },
    // {
    //   name: "Endrew's Bradford Crib",
    //   icon: images.b,
    //   location: { lat: 44.107368081219384, lng: -79.5867931206362 },
    //   content: '<h4>Endrew\'s Bradford Crib</h4><br><p>near 470 Holland St W, Bradford, ON L3Z 0A2, Canada<br><a href="https://goo.gl/maps/qVw6uYvv8HKd2Kv88" target="blank">View on Google Maps</a><br><a href="https://gtapong.com/bradford-pong" target="blank">Pics & Details</a>',
    // },
  ];

  let scaledSize = 18;

  return (
    <LoadScript googleMapsApiKey="AIzaSyBPX2ooMEpbTtlg2Obr5XYEE53SwW0t3CY">
      <GoogleMap
        mapContainerStyle={dapStyles}
        zoom={8.5}
        center={center}
        options={{
          styles: snazzyStyles,
          scrollwheel: true,
          disableDefaultUI: true,
          //panControl: true,
          //zoomControl: true,
          //mapTypeControl: true,
          //scaleControl: true,
          //streetViewControl: true,
          //overviewMapControl: true,
          //rotateControl: true,
          //fullscreenControl: true,
        }}
      >
        {locations.map((item) => {
          return (
            <Marker
              key={item.name}
              position={item.location}
              onClick={() => onSelect(item)}
              //THIS ONE WORKS FOR CUSTOM ICONS!! BELOW
              options={{
                icon: {
                  url: item.icon,
                  scaledSize: { width: scaledSize, height: scaledSize },
                },
              }}
              //options={{icon: {url: require("../../assets/images/icons/x.png"), scaledSize: { width: scaledSize, height: scaledSize }}}}
            />
          );
        })}

        {selected.location && (
          <InfoWindow
            position={selected.location}
            clickable={true}
            onCloseClick={() => setSelected({})}
            // options={{disableAutoPan: true}}
          >
            <div>
              <h5 style={{ color: "black" }}>{selected.name}</h5>
              <br></br>
              <p style={{ color: "black" }}>Address: {selected.address}</p>
              <br></br>
              <p>
                {" "}
                <a
                  style={{ color: "blue" }}
                  href={selected.gmaps}
                  target="_blank"
                >
                  View on Google Maps
                </a>
              </p>
              <br></br>
              <p>
                <a
                  style={{ color: "blue" }}
                  href={selected.details}
                  target="_blank"
                >
                  Pics & Details
                </a>
              </p>
              <br></br>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default MapContainer;
