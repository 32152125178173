import a from '../../assets/images/icons/a.png'
import b from '../../assets/images/icons/b.png'
import c from '../../assets/images/icons/c.png'
import d from '../../assets/images/icons/d.png'
import e from '../../assets/images/icons/e.png'
import f from '../../assets/images/icons/f.png'
import g from '../../assets/images/icons/g.png'
import h from '../../assets/images/icons/h.png'
import i from '../../assets/images/icons/i.png'
import j from '../../assets/images/icons/j.png'
import k from '../../assets/images/icons/k.png'
import l from '../../assets/images/icons/l.png'
import m from '../../assets/images/icons/m.png'
import n from '../../assets/images/icons/n.png'
import o from '../../assets/images/icons/o.png'
import p from '../../assets/images/icons/p.png'
import q from '../../assets/images/icons/q.png'
import r from '../../assets/images/icons/r.png'
import s from '../../assets/images/icons/s.png'
import t from '../../assets/images/icons/t.png'
//import u from '../../assets/images/icons/u.png'
import v from '../../assets/images/icons/v.png'
import w from '../../assets/images/icons/w.png'
import x from '../../assets/images/icons/x.png'
import y from '../../assets/images/icons/y.png'
import z from '../../assets/images/icons/z.png'
import fg from '../../assets/images/icons/fgbradleys.png'



const images = {
  a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,v,w,x,y,z,fg
};

export default images;